{
  "apiKey": "AIzaSyA4OZblgVV2e1iJiEroOECZgmcIuHGv20o",
  "authDomain": "nan-pre-prod.firebaseapp.com",
  "projectId": "nan-pre-prod",
  "storageBucket": "nan-pre-prod.appspot.com",
  "messagingSenderId": "407939450086",
  "appId": "1:407939450086:web:ff42b26efb787c8bcfe727",
  "pdfViewer": "demo:1687500872689:7d9b0bf5030000000004d8601e60ede55b305d3b4c878ee15b5d56198d",
  "microsoftTenantId": "3b9cc8c8-869a-4f7b-acbc-e7db8a596f01",
 "tenantId": "StagingUsers-s5cx9",
  "socketNamespace": "chat"
}